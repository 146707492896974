











































import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { UserStoreModule } from '@common-src/store/modules/user';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import CommonService from '@common-src/service/common';

@Component
export default class PlatformBasicSettingComponent extends BaseComponent {
    theme = [{
        name: '深色',
        type: 'dark'
    }, {
        name: '浅色',
        type: 'light',
        checked: true
    }];
    primary = [{
        id: 1,
        type: '#2393cd',
        checked: true
    }, {
        id: 2,
        type: 'red'
    }, {
        id: 3,
        type: 'green'
    }, {
        id: 4,
        type: '#a818cc'
    }, {
        id: 5,
        type: 'orange'
    }];
    // ['#2393cd','red','green','#a818cc','orange']
    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<any>;
    @Ref()
    safeFrom: FormComponent<any>;
    mounted() {
        CommonService.getPageSettingInfo().then((res) => {
            res.themeStyle && (this.theme = _.map(this.theme, item => {
                item.checked = item.type === res.themeStyle ? true : false;
                return item;
            }));
            res.themeColor && (this.primary = _.map(this.primary, item => {
                item.checked = item.type === res.themeColor ? true : false;
                return item;
            }));
            const logoValue = _.get(res, 'logoUrl');
            const BaseList = [
                {
                    key: 'dispName',
                    label: '平台名称',
                    type: FormControlType.TEXT,
                    layout: { labelCol: 2, wrapperCol: 12, width: '100% ' },
                    required: true,
                    value: res.dispName,
                    maxLength: 20
                },
                {
                    key: 'logoUrl',
                    label: '平台logo',
                    value: logoValue ? [{ url: logoValue, type: 'image' }] : [],
                    type: FormControlType.UPLOAD,
                    listType: 'picture-card',
                    showUploadList: true,
                    notUploadOss: true,
                    uploadUrl: '/infra-common/api/filestorage/upload',
                    maxFileNumber: 1,
                    noMessageTooltip: true,
                    message: '建议大小为：150px*30px，大小不大于1M，格式：png、jpg',
                    layout: { labelCol: 2, wrapperCol: 12, width: '100% ' },
                    accept: '.jpg,.png',
                    required: true
                }
            ];
            const SafeList = [
                {
                    key: 'defaultPassword',
                    label: '默认密码',
                    type: FormControlType.TEXT,
                    placeholder: '6个字符及以上，最少包含大写字母、小写字母、数字、符号中的两种',
                    layout: { labelCol: 2, wrapperCol: 12, width: '100% ' },
                    required: true,
                    value: res.defaultPassword
                }
            ];
            this.jtlForm.initFormByControls(BaseList, ViewModeType.UPDATE);
            this.safeFrom.initFormByControls(SafeList, ViewModeType.UPDATE);
        });
    }
    saveClick() {
        Promise.all([this.jtlForm.validate(), this.safeFrom.validate()]).then((res) => {
            if (!_.every(res, item => item)) {
                return;
            }
            const logoUrl = _.get(this.jtlForm.formModel.logoUrl, '[0].url');
            const themeStyle = _.get(_.find(this.theme, item => item.checked), 'type');
            const themeColor = _.get(_.find(this.primary, item => item.checked), 'type');
            const dispName = _.get(this.jtlForm.formModel, 'dispName');
            const defaultPassword = _.get(this.safeFrom.formModel, 'defaultPassword');
            const params = {
                themeStyle,
                themeColor,
                dispName,
                defaultPassword,
                logoUrl
            };
            CommonService.setPageSettingInfo(params).then(() => {
                this.$eventHub.$emit('UPDATE_THEME', themeStyle);
                // 修改logoUrl
                UserStoreModule.SET_LOGO_URL(logoUrl);
                // 更新PlatformName
                UserStoreModule.SET_PLATFORM_NAME(dispName);
                document.body.style.setProperty('--primaryColor', themeColor);
                window['less'].modifyVars({
                    '@primary-color': themeColor
                }).then(() => { console.log('success'); }).catch(err => console.log(err));
                localStorage.setItem('PRIMARY_COLOR', themeColor);
                this.showMessageSuccess('保存成功！');
            });
        });
    }
    switchTheme(index) {
        const result = this.theme;
        const theme = _.map(result, (item, i) => {
            item.checked = index === i ? true : false;
            return item;
        });
        this.theme = theme;
    }
    switchPrimary(index) {
        const result = this.primary;
        const primary = _.map(this.primary, (item, i) => {
            item.checked = index === i ? true : false;
            return item;
        });
        this.primary = primary;
    }
}

